<template>
    <div class="card mb-0">
        <div class="card-header">Печать</div>
        <div class="card-body" style="padding: 0 !important;">
            <errors-bag-list></errors-bag-list>
            <div class="col-6 pt-3">
                <b-form-group description="Принтер">
                    <printer-picker :for-material-storage="!!act.storageFrom ? act.storageFrom.material : null" v-model="printer"></printer-picker>
                </b-form-group>
            </div>
            <b-table striped :responsive="true" :items='items' :fields='fields' head-variant='dark' :no-local-sorting='true'>
                <template #cell(nomenclatureLot)="data">
                    {{ data.value.nomenclature.name }}&nbsp;&nbsp;
                    <b>{{ data.item.nomenclatureLot.name }}</b>&nbsp;&nbsp;
                    <b>{{ data.item.count }} {{ data.value.nomenclature.measureUnit.name }}</b>
                </template>
                <template #cell(duplicates)="data">
                    <input type="number" class="form-control" placeholder="Количество" v-model='data.item.duplicates'/>
                </template>
            </b-table>
        </div>
        <div class="card-footer text-right">
            <button class="btn btn-primary" :disabled="loading" @click="appendToPrintQueue">
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                Отправить на печать
            </button>
        </div>
    </div>
</template>

<script>
    import filter from 'lodash/filter';
    import forEach from 'lodash/forEach';

    import PrinterPicker from "../_common/devices/PrinterPicker";
    import ErrorsBag from '../../utils/errorsBag';
    import ErrorsBagList from '../_common/ErrorsBagList';
    import {PRINT_QUEUES} from '../../utils/endpoints';

    export default {
        name: "MaterialTransferPrintModal",
        components: {ErrorsBagList, PrinterPicker},
        props: {
            act: {
                type: Object,
                required: true,
            },
            successCallback: {
                type: Function,
                required: true,
            }
        },
        data() {
            return {
                ErrorsBag,
                items: [],
                fields: [
                    {key: 'nomenclatureLot', label: 'Номенклатура'},
                    {key: 'duplicates', label: 'Количество'}
                ],
                printer: null,
                loading: false,
            };
        },
        methods: {
            hide() {
                this.items = [];

                ErrorsBag.discard();
            },
            appendToPrintQueue() {
                if (this.loading) {
                    return;
                }

                ErrorsBag.discard();

                let params = {
                    printer_id: this.printer ? this.printer.id : null,
                    material_transfer_act: {
                        id: this.act.id,
                        items: filter(this.items, item => item.duplicates > 0)
                    }
                };

                this.loading = true;
                this.$http.post(PRINT_QUEUES + '/append-to/material-transfer-nomenclature', params, {
                    params: {
                        without_loading: true
                    }
                }).then(() => {
                    this.successCallback();
                    this.$emit('close');
                }).catch(response => {
                    ErrorsBag.fill(response.body)
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {
            forEach(this.act.items, item => {
                let record = {
                    id: item.id,
                    duplicates: 0,
                    nomenclatureLot: item.nomenclatureLot,
                    count: parseFloat(item.count).toFixed(2)
                };

                this.items.push(record);
            });
        }
    }
</script>

<style scoped lang='sass'>

</style>
