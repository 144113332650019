<template>
    <b-alert :show="!!errors.length"
             @dismissed="$emit('clear')"
             dismissible
             variant="danger"
             style="padding: 0.75rem 0 0;"
    >
        <ul class="text-left" style="list-style: none; padding-left: 1rem;">
            <li v-for="error in errors">
                {{ messageFunction ? messageFunction(error) : error.message }}
            </li>
        </ul>
    </b-alert>
</template>

<script>
    export default {
        props: {
            errors: {
                required: true,
                type: Array
            },
            messageFunction: {
                type: Function
            }
        }
    }
</script>
