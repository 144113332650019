<template>
    <div>
        <div class="user-and-date-wrapper" v-if="authorExists">
            <span class="subject"><strong>Создал:</strong></span>
            <span class="user-and-date" v-html="authorAndActDate"></span>
        </div>

        <div class="user-and-date-wrapper no-margin" v-if="receivedByExists">
            <span class="subject"><strong>Получил:</strong></span>
            <span class="user-and-date" v-html="receivedByAndReceivedAt"></span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    const FORMAT_DATE = 'DD.MM.YYYY HH:mm:ss';

    export default {
        name: "UsersOfTheAct",
        props: {
            act: Object,
            default: null
        },
        computed: {
            actExists() {
                return !( this.act === null || typeof this.act === 'undefined' );
            },
            authorExists() {
                return !(
                    !this.actExists ||
                    (this.act.date === null || typeof this.act.date === 'undefined') ||
                    (this.act.author === null || typeof this.act.author === 'undefined') ||
                    (this.act.author.username === null || typeof this.act.author.username === 'undefined')
                );
            },
            authorAndActDate() {
                let actDate = moment( this.act.date ).format( FORMAT_DATE );
                let authorName = this.act.author.username;

                return '<em>' + authorName + ' (' + actDate + ')</em>';
            },
            receivedByExists() {
                return !(
                    !this.actExists ||
                    (this.act.receivedBy === null || typeof this.act.receivedBy === 'undefined') ||
                    (this.act.received_at === null || typeof this.act.received_at === 'undefined') ||
                    (this.act.receivedBy.username === null || typeof this.act.receivedBy.username === 'undefined')
                );
            },
            receivedByAndReceivedAt() {
                let receivedAt = moment( this.act.received_at ).format( FORMAT_DATE );
                let receivedBy = this.act.receivedBy.username;

                return '<em>' + receivedBy + ' (' + receivedAt + ')</em>';
            },
        }
    }
</script>

<style lang="sass" scoped>
  .user-and-date-wrapper
      width: 100%
      display: flex
      padding: 0 2px
      margin-top: 20px
      justify-content: space-between

      &.no-margin
          margin: 0

      & .subject,
      & .user-and-date
          display: flex
</style>
