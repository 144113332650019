<template>
    <div style="overflow-x: hidden" class="act-form material-transfer-act-form" v-if="act">
        <error-list :errors="errors" :message-function="errorMessage" @clear="errors = []"></error-list>

        <b-tabs pills card >
            <b-tab title="Сырьё" active>
                <h3 class="text-center" style="padding: 10px;margin: 0;">{{ act.storageTo.name }}</h3>
                <div style="margin: 10px 0 20px 0; padding: 0 12px;">
                    <div v-if="act.shift">Передача на смену: <b>{{ act.shift.shift.name }}</b></div>
                    <div v-else>Смена неизвестна</div>

                    <div v-if="act.production_plan_date">
                        Отгрузка: <b>{{ moment( act.production_plan_date ).format( 'DD.MM.YYYY' ) }}</b>
                    </div>
                    <div v-else>Дата отгрузки не указана</div>
                </div>
                <table class="table table-striped">
                    <tbody>
                        <tr v-for="item in groupedItems">
                            <td>{{ item.name }}</td>
                            <td class="text-right">
                                {{ parseFloat( item.count ).toFixed( 3 ) }} {{ item.measureUnit }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-tab>
            <b-tab :title="(act.comment ? '⭐' : '') + 'Коммент.'">
                <b-form-textarea v-model="act.comment" :disabled="disabled || act.received" :rows="6"></b-form-textarea>
            </b-tab>
        </b-tabs>

        <control-panel >
            <button @click="goBack" class="btn btn-danger">Назад</button>
            <div>
                <button class="btn btn-success" v-if="act && !act.received" @click="receive(true)">Принять</button>
                <button class='btn btn-warning' v-if='act && act.id' @click='openPrintQueueModal'>
                    <i class='fas fa-print'></i>
                </button>
                <button class="btn btn-warning"
                        v-else-if="act && !disabled"
                        :disabled="!act.access.can_receive"
                        @click="receive(false)">Отменить приемку</button>
                <button class="btn btn-warning"
                        v-if="act && !disabled && !act.received"
                        :disabled="!act.access.can_cancel"
                        @click="cancel()">Отбраковать</button>
            </div>
        </control-panel>

        <users-of-the-act :act="act"></users-of-the-act>
    </div>
</template>

<script>
    import moment from 'moment';
    import ErrorList from '../_common/ErrorList';
    import ControlPanel from '../_common/ControlPanel';
    import { MATERIAL_TRANSFER_ACT_ENDPOINT } from "../../utils/endpoints";
    import {mapActions} from 'vuex';
    import UsersOfTheAct from '../_common/UsersOfTheAct';
    import MaterialTransferPrintModal from "./MaterialTransferPrintModal";

    export default {
        components: {
            ErrorList,
            ControlPanel,
            UsersOfTheAct,
            MaterialTransferPrintModal
        },
        props: {
            id: {
                required: false
            },
            actToShow: {
                type: Object,
                required: false
            },
            onSuccess: {
                type: Function,
                required: false,
                default: null
            },
            onError: {
                type: Function,
                required: false,
                default: null
            },
            goBackCallback: {
                type: Function,
                required: false,
                default: null
            }
        },
        computed: {
            disabled() {
                return this.act && this.act.blocked || this.act.canceled;
            },
            groupedItems() {
                if ( !this.act ) {
                    return [];
                }

                let items = {};

                this.act.items.map( item => {
                    let nomenclature = item.nomenclatureLot.nomenclature;

                    if ( !items[ nomenclature.id ] ) {
                        items[ nomenclature.id ] = {
                            count: 0,
                            name: nomenclature.name,
                            measureUnit: nomenclature.measureUnit.name
                        };
                    }

                    items[ nomenclature.id ].count += parseFloat( item.count );
                } );

                return items;
            }
        },
        watch: {
            id() {
                this.fetchAct()
            }
        },
        data() {
            return {
                act: null,
                errors: [],
                with: [
                    'shift.shift',
                    'storageFrom',
                    'storageTo',
                    'author',
                    'receivedBy',
                    'items.nomenclatureLot.nomenclature.measureUnit'
                ],
            }
        },
        methods: {
            moment,
            getBaseUrl() {
                return MATERIAL_TRANSFER_ACT_ENDPOINT;
            },
            receive(flag) {
                if (!confirm(flag ? 'Принять?' : 'Отменить приемку?')) {
                    return;
                }

                this.$http.put(this.getBaseUrl() + `/${this.act.id}/receive`, {
                        with: this.with,
                        comment: this.act.comment,
                        received: flag
                    })
                    .then(response => {
                        this.act = response.body;
                        if (!!this.onSuccess) {
                            this.onSuccess(this.act);
                            return;
                        }

                        if (flag) {
                            this.$router.go(-1);
                        }
                    })
                    .catch (error => {
                        this.errors.push(error.body.message);
                        if (!!this.onError) {
                            this.onError(this.errors);
                            return;
                        }
                    });
            },
            cancel() {
                if (!confirm(`После подтверждения акт передачи будет удален, сырье будет возвращено на склад "${this.act.storageFrom.name}".\n\nПродолжить?`)) {
                    return;
                }

                this.$http.put(this.getBaseUrl() + `/${this.act.id}/cancel`, {
                        comment: this.act.comment,
                        canceled: !this.act.canceled,
                    })
                    .then(response => {
                        this.act.canceled = !this.act.canceled;

                        if (!!this.onSuccess) {
                            this.onSuccess(this.act);
                            return;
                        }

                        this.$router.go(-1);
                    })
                    .catch(error => {
                        this.errors.push(error.body.message);
                        if (!!this.onError) {
                            this.onError(this.errors);
                            return;
                        }
                    });
            },
            fetchAct() {
                if (this.actToShow) {
                    this.act = this.actToShow;
                    return;
                }

                if (!this.id) {
                    return;
                }

                this.$http.get(this.getBaseUrl() + '/' + this.id, {params: {
                        with: this.with
                    }})
                    .then(response => {
                        this.act = response.data;
                    })
            },
            goBack() {
                if (!!this.goBackCallback) {
                    this.goBackCallback();
                    return;
                }

                this.$router.push({name: 'MaterialTransferActReceiveList'});
            },
            errorMessage(error) {
                if (typeof error === 'string') {
                    return error
                }

                let matchResult = error.propertyPath.match(/^items\[(\d+)]/),
                    message = error.message;

                if (matchResult) {
                    message = `${this.act.items[matchResult[1]].nomenclature.name}: ${message}`
                }

                return message
            },
            ...mapActions({
                refreshAvailableToReceiveCount: 'productiontask/availableToReceiveCount/refresh',
            }),

            openPrintQueueModal() {
                this.$modal.show(MaterialTransferPrintModal, {
                    act: this.act,
                    successCallback: () => {}
                }, {
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                    scrollable: true,
                })
            }
        },
        mounted() {
            this.fetchAct()
        }
    }
</script>
