<template>
    <select @change="onChange()" class="form-control" v-model="printerId" :disabled="isDisabled()">
        <option :disabled="!nullable" :value="null">Выберите принтер</option>
        <option v-for="option in options" :value="option.id">{{option.name}}</option>
    </select>
</template>

<script>
    import {PRINTER_ENDPOINT} from '@utils/endpoints';

    export default {
        name: "PrinterPicker",
        props: {
            value: {
                required: true,
            },
            nullable: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            forMaterialStorage: {
                type: Boolean,
                required: false,
                default: null,
            }
        },
        watch: {
            value() {
                this.printerId = this.value ? this.value.id : null;
            },
            loading(val) {
                if (val) {
                    this.printerId = -1;
                }
            }
        },
        data() {
            return {
                printerId: null,
                loading: false,
                options: []
            };
        },
        methods: {
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(PRINTER_ENDPOINT, {
                    params: {
                        limit: 0,
                        without_loading: true
                    }
                }).then(response => {
                    this.options = response.data.data.data;
                    this.setDefault();
                }).catch(response => this.$root.responseError(response, 'Ошибка загрузки принтеров')).finally(() => {
                    this.loading = false;
                });
            },
            setDefault() {
                if (this.forMaterialStorage !== null) {
                    let value = this.options.find(p => p.for_material_storage === this.forMaterialStorage);
                    if (value) {
                        this.printerId = value.id
                        this.onChange();
                    }
                }
            },
            onChange() {
                this.$emit('input', this.options.find(p => p.id === this.printerId));
            },
            isDisabled() {
                return this.disabled || this.loading;
            },
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
